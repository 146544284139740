// KeyMetricsBarChart.jsx
import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import jsonData from "./exec--keyMetrics.json"; // Import JSON data

const KeyMetricsBarChart = () => {
  const [chartData, setChartData] = useState({
    series: [],
    categories: [],
  });

  // Function to extract month-year from alert_date
  const getMonthYear = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}`;
  };

  // useEffect to process data on component mount
  useEffect(() => {
    // Group data by alert_type and month
    const groupedData = jsonData.reduce((acc, item) => {
      const monthYear = getMonthYear(item.alert_date);
      const alertType = item.alert_type;

      if (!acc[alertType]) {
        acc[alertType] = {};
      }
      if (!acc[alertType][monthYear]) {
        acc[alertType][monthYear] = 0;
      }
      acc[alertType][monthYear] += 1;
      return acc;
    }, {});

    // Prepare series data for the chart
    const alertTypes = Object.keys(groupedData);
    const months = Array.from(
      new Set(jsonData.map((item) => getMonthYear(item.alert_date)))
    ).sort();

    const series = alertTypes.map((type) => ({
      name: type,
      data: months.map((month) => groupedData[type][month] || 0),
    }));

    // Set the data in the state
    setChartData({
      series,
      categories: months,
    });
  }, []);

  // Chart configuration options
  const options = {
    chart: {
      type: "bar",
      height: "70%",
      stacked: true,
    },
    colors: [
      "#005f73",
      "#94d2bd",
      "#e9d8a6",
      "#ee9b00",
      "#ca6702",
      "#bb3e03",
      "#ae2012",
      "#9b2226",
      "#001219",
      "#0a9396",
    ],
    xaxis: {
      categories: chartData.categories,
      title: {
        text: "Month",
      },
      labels: {
        rotate: -45,
      },
    },
    yaxis: {
      title: {
        text: "Number of Alerts",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => `${val} alerts`,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val.toString(),
      offsetY: -10,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div className="key-metrics-bar-chart">
      <h1>Key Metrics Analysis - Stacked Bar Chart</h1>
      <div id="chart">
        <ApexCharts options={options} series={chartData.series} type="bar" />
      </div>
    </div>
  );
};

export default KeyMetricsBarChart;
