import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import jsonData from "./exec-decentralized.json"; // Import JSON data

const DecentralizedResilience = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    series: [],
    scores: [],
    overallScore: 0,
  });

  // UseEffect for setting data once on component mount
  useEffect(() => {
    // Extract data from the imported JSON
    const areaNames = jsonData.map((item) => item.resilience_area_name);
    const areaWeights = jsonData.map((item) =>
      parseFloat(item.resilience_area_weight)
    );
    const areaScores = jsonData.map((item) =>
      parseFloat(item.resilience_area_score)
    );

    // Calculate the overall weighted score
    const overallScore = jsonData
      .reduce((sum, item) => sum + parseFloat(item.weighted_score), 0)
      .toFixed(2);

    // Set the data in the state
    setChartData({
      labels: areaNames,
      series: areaWeights,
      scores: areaScores,
      overallScore,
    });
  }, []);

  // Chart configuration options
  const options = {
    chart: {
      type: "donut",
      height: "100%",
    },
    labels: chartData.labels,
    fill: {
      colors: [
        "#ee9b00",
        "#005f73",
        "#94d2bd",
        "#ca6702",
        "#e9d8a6",
        "#bb3e03",
        "#ae2012",
        "#9b2226",
        "#001219",
        "#0a9396",
      ],
    },
    legend: {
      position: "right", // Move legend to the right side
      fontSize: "14px",
      markers: {
        width: 12,
        height: 12,
      },
      labels: {
        colors: "#304758",
      },
      itemMargin: {
        vertical: 5,
      },
    },
    tooltip: {
      y: {
        formatter: (val, { seriesIndex }) =>
          `${chartData.labels[seriesIndex]}: ${chartData.scores[seriesIndex]} (Weight: ${val})`,
      },
    },
    dataLabels: {
      enabled: false, // Disable labels on the chart to avoid clutter
    },
    plotOptions: {
      pie: {
        customScale: 0.95,
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Decentralized",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              formatter: () => `${chartData.overallScore}`,
            },
          },
        },
      },
    },
  };

  return (
    <div className="decentralized-resilience">
      <h1>Decentralized Resilience</h1>
      <div id="chart">
        <ApexCharts options={options} series={chartData.series} type="donut" />
      </div>
      <div id="overall-score">
        Weighted Score: {chartData.overallScore}
      </div>
    </div>
  );
};

export default DecentralizedResilience;
