// File: src/components/Sidebar2/index.jsx

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './sidebar2.css';

const Sidebar2 = ({ isOpen, toggleSidebar }) => {
    const navigate = useNavigate();

    // Logout function
    const handleLogout = async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('lastVisitedPath'); // Optional: Clear the last visited path
            navigate('/'); // Redirect to the login page
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className={`sidebar2 ${isOpen ? 'open' : ''}`}>
            <button className="sidebar2-closebtn" onClick={toggleSidebar}>&times;</button>
            <div className="sidebar2-menu-content">
                <Link to="/discover" onClick={toggleSidebar}>Discover</Link>
                <Link to="/compose" onClick={toggleSidebar}>Compose</Link>
                <Link to="/analyze" onClick={toggleSidebar}>Analyze</Link>
                <Link to="/analyze4" onClick={toggleSidebar}>Manage Users</Link>
                <Link to="/analyze2" onClick={toggleSidebar}>Help</Link>
                {/* Updated Logout Link */}
                <Link to="#" onClick={handleLogout}>Logout</Link>
            </div>
        </div>
    );
};

export default Sidebar2;
