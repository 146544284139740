import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const ProtectedRoute = ({ children }) => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
  const location = useLocation();

  console.log(`ProtectedRoute - Current route: ${authStatus}`);
  console.log(`ProtectedRoute - Authenticated user:`, user);

  // Handle different states of the auth process
  if (authStatus === 'configuring' || authStatus === 'loading') {
    // While auth is still being configured or loading, show a loading indicator
    return <div>Loading...</div>;
  }

  // If the authStatus is "authenticated" and a user is present, allow access
  if (authStatus === 'authenticated' && user) {
    console.log('ProtectedRoute - User is authenticated, allowing access');
    return children;
  }

  // If the user is not authenticated, redirect to the login page
  console.log('ProtectedRoute - User is not authenticated, redirecting to login');
  return <Navigate to="/" state={{ from: location }} replace />;
};

export default ProtectedRoute;
