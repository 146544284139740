// File: src/pages/DiscoverPage.js

import { Helmet } from "react-helmet";
import Sidebar2 from "../../components/Sidebar2";
import Header from "../../components/Header"; // Import the Header component
import React, { useRef, useState } from "react";
import './Discover.css';

export default function DiscoverPage() {
  const iframeRef = useRef(null);
  const [isSidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Helmet>
        <title>Discover Features - AI Architect Compose</title>
        <meta
          name="description"
          content="Discover the powerful features of AI Architect Compose. From composing to analyzing, learn how our platform can streamline your workflow."
        />
      </Helmet>

      <Header toggleSidebar={toggleSidebar} /> {/* Add Header with sidebar toggle functionality */}

      <div className="discover-page">
        <Sidebar2 isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> {/* Sidebar2 component with state */}
        <div className="content-area">
          <iframe
            ref={iframeRef}
            className="iframe-content"
            id="dxy_frame"
            name="dxy_frame"
            src="https://docxonomy.aiarchitectsolutions.com/html/docx-embedded-search.html"
            title="Docxonomy"
          ></iframe>
        </div>
      </div>
    </>
  );
}
