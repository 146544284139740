// File: src/components/Header/index.jsx

import React from 'react';
import { useLocation } from 'react-router-dom';
import hamburgerMenuImg from '../Sidebar2/HamburgerMenu_img.png';
import stylizedSLogo from './stylized-S-logo.png'; // Import the stylized 'S' logo

const Header = ({ toggleSidebar }) => {
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case '/compose':
        return 'Compose';
      case '/discover':
        return 'Discover';
      case '/analyze':
        return 'Analyze';
      case '/analyze2':
        return 'Analyze R&D';
      case '/analyze3':
        return 'Analyze Supplier';
      case '/analyze4':
        return 'Analyze FTR';
      default:
        return 'Page';
    }
  };

  return (
    <header className="header-container">
      <button onClick={toggleSidebar} className="toggle-sidebar-btn">
        <img src={hamburgerMenuImg} alt="Sidebar" className="sidebar-img" />
        <img src={stylizedSLogo} alt="Stylized S Logo" className="stylized-s-logo" />
      </button>
      <h1 className="header-title">{getTitle()}</h1>
    </header>
  );
};

export default Header;
