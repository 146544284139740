// File: src/components/InvalidatedOOSApiData.js

import React, { useState, useEffect } from 'react';
import invalidatedOOSFile from '../../../Invalidated-OOS4Demo_api.json';

const InvalidatedOOSApiData = () => {
  const [invalidatedOOSResult, setInvalidatedOOSResult] = useState(null);
  const [error, setError] = useState(null);

  const fetchInvalidatedOOSApiData = async () => {
    const url = 'https://app.studio.arria.com:443/alite_content_generation_webapp/text/5Z3PZ5YamB9';
    const bearerToken = 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJFdV9qSXRMNlhiaUpod3pEeHpJeVZJNXYiLCJpYXQiOjE3MjQ4Nzc5ODAsImV4cCI6MTg4MjU1Nzk4MCwiaXNzIjoiQUxpdGUiLCJzdWIiOiJNNjZORDVjdHMxZ0ciLCJBTGl0ZS5wZXJtIjpbInByczp4OjVaM1BaNVlhbUI5Il0sIkFMaXRlLnR0IjoidV9hIn0.YygM3qKl3bqPjCifrP_qgCGuIa7eq_-e7sbFW2SDzRihS2YbUTbD4cBd8_hoD72t2EKwYLVEGrzGD7ir6JORHA';

    try {
      const binaryData = JSON.stringify(invalidatedOOSFile);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${bearerToken}`,
        },
        body: binaryData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      // Extract and clean the result
      if (Array.isArray(data) && data.length > 0 && data[0].hasOwnProperty('result')) {
        const rawResult = data[0].result;
        const cleanResult = rawResult.replace(/<[^>]+>/g, ''); // Remove HTML tags
        setInvalidatedOOSResult(cleanResult);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching Invalidated OOS Chart API data:', error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchInvalidatedOOSApiData();
  }, []);

  return (
    <div className="api-container" style={{ padding: '10px', overflow: 'auto', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
      {error ? (
        <p style={{ color: 'red' }}>Error: {error}</p>
      ) : invalidatedOOSResult ? (
        <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {invalidatedOOSResult}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default InvalidatedOOSApiData;
