// File: src/components/QualityApiData.js

import React, { useState, useEffect } from 'react';
import Qualityfile from '../../../ProductQuality4Demo_api.json'; // Ensure the correct relative path to your file

const QualityApiData = () => {
  const [qualityApiData, setQualityApiData] = useState(null);
  const [error, setError] = useState(null);

  // Function to fetch the API data
  const fetchQualityApiData = async () => {
    const url = 'https://app.studio.arria.com:443/alite_content_generation_webapp/text/BoY2q7nNrM';
    const bearerToken = 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJJNjN6ZFBlVEtCYzZSam0tSWRZX0Q3YzIiLCJpYXQiOjE3MjQ4Nzc3MzksImV4cCI6MTg4MjU1NzczOSwiaXNzIjoiQUxpdGUiLCJzdWIiOiJlaXhyMERNQVlhbDQiLCJBTGl0ZS5wZXJtIjpbInByczp4OkJvWTJxN25Ock0iXSwiQUxpdGUudHQiOiJ1X2EifQ.xw58f_Vsa9nKdbG60CKoDRBDktTTBKHBMokgS2lUDxZzUTW8fbXAsSNTEIINU6MbfkLc83ADcDaMxRKdrsp-uA';

    try {
      // Convert the imported file into a JSON string format
      const binaryData = JSON.stringify(Qualityfile);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${bearerToken}`, // Authorization header
        },
        body: binaryData, // Send the data as JSON string
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Log the response to inspect the structure
      console.log('API Response:', data);

      // Check if the response is an array and contains the expected structure
      if (Array.isArray(data) && data.length > 0 && data[0].hasOwnProperty('result')) {
        const rawResult = data[0].result;
        const cleanResult = rawResult.replace(/<[^>]+>/g, ''); // Remove HTML tags
        setQualityApiData(cleanResult); // Set cleaned result
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching Quality API data:', error.message);
      setError(error.message);
    }
  };

  // Trigger the fetch when the component loads
  useEffect(() => {
    fetchQualityApiData();
  }, []);

  return (
    <div
      className="api-container"
      style={{
        padding: '10px',
        overflow: 'auto',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
      }}
    >
      {error ? (
        <p style={{ color: 'red' }}>Error: {error}</p> // Display the error message if one exists
      ) : qualityApiData ? (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {qualityApiData} {/* Display only the result text */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default QualityApiData;
