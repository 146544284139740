import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button } from "../../components";
import LandingPageStackvectorOne from "../../components/LandingPageStackvectorOne";
import { ReactTable } from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Suspense, useState } from "react"; // Added useState import

const data = [
  { salesAmount: "$230,220", salesStatus: "+55% since last month", salesLabel: "Sales" },
  { salesAmount: "3.200", salesStatus: "+12% since last month", salesLabel: "Customers" },
  { salesAmount: "3.200", salesStatus: "+12% since last month", salesLabel: "Avg. Revenue" },
];

const tableData = [
  { productHeader: "Nike v22 Running", valueHeader: "$130.992", adsSpentHeader: "$9.500", refundsHeader: "13" },
  {
    productHeader: "Business Kit (Mug + Notebook)",
    valueHeader: "\t$80.250",
    adsSpentHeader: "$4.200",
    refundsHeader: "40",
  },
  { productHeader: "Black Chair", valueHeader: "$40.600", adsSpentHeader: "$9.430", refundsHeader: "54" },
  { productHeader: "Wireless Charger", valueHeader: "$91.300", adsSpentHeader: "$7.364", refundsHeader: "5" },
  {
    productHeader: "Mountain Trip Kit (Camera + Backpack)",
    valueHeader: "$140.925",
    adsSpentHeader: "$20.531",
    refundsHeader: "121",
  },
];

export default function LandingPage() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("productHeader", {
        cell: (info) => (
          <div className="flex items-center gap-4">
            <Img
              src="images/img_image_product.png"
              alt="product image"
              className="ml-3 h-[48px] w-[48px] rounded-[24px] object-cover"
            />
            <div className="flex flex-col items-start gap-0.5">
              <Heading as="p" className="!text-blue_gray-800">
                {info?.getValue?.()}
              </Heading>
              <Text as="p" className="!text-blue_gray-800">
                <span className="text-green-500">8.232</span>
                <span className="text-blue_gray-800">&nbsp;</span>
                <span className="text-blue_gray-400_01">orders</span>
              </Text>
            </div>
          </div>
        ),
        header: (info) => (
          <Heading size="xs" as="h3" className="pb-4 pl-[25px] pt-px sm:pl-5">
            PRODUCT
          </Heading>
        ),
        meta: { width: "735px" },
      }),
      tableColumnHelper.accessor("valueHeader", {
        cell: (info) => <Text as="p">{info?.getValue?.()}</Text>,
        header: (info) => (
          <Heading size="xs" as="h4" className="pb-4 pt-px">
            VALUE
          </Heading>
        ),
        meta: { width: "219px" },
      }),
      tableColumnHelper.accessor("adsSpentHeader", {
        cell: (info) => <Text as="p">{info?.getValue?.()}</Text>,
        header: (info) => (
          <Heading size="xs" as="h5" className="px-px pb-4">
            ADS SPENT
          </Heading>
        ),
        meta: { width: "205px" },
      }),
      tableColumnHelper.accessor("refundsHeader", {
        cell: (info) => (
          <div className="flex items-start gap-1.5">
            <Text as="p" className="mb-[22px] mt-[19px] h-[17px] w-[16px]">
              {info?.getValue?.()}
            </Text>
            <Img src="images/img_arrow_down_green_500.svg" alt="arrow down" className="mt-6 h-[8px] w-[8px]" />
          </div>
        ),
        header: (info) => (
          <Heading size="xs" as="h6" className="px-px pb-4">
            REFUNDS
          </Heading>
        ),
        meta: { width: "119px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Sales Dashboard - Insights and Analytics</title>
        <meta
          name="description"
          content="Explore your sales performance with our Sales Dashboard. Analyze customer trends, revenue, and sales by age and country. Make data-driven decisions to boost your sales."
        />
      </Helmet>

      <div className="w-full bg-gray-100 p-6 sm:p-5">
        <div className="mb-[5px] mt-[11px] flex flex-col items-end gap-[22px]">
          <div className="flex items-start gap-6 self-stretch md:flex-col">
            <div className="mt-[5px] flex-1 md:self-stretch">
              <div className="mt-[41px]">
                <div className="flex flex-col gap-6">
                  <div className="flex gap-6 md:flex-col">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {data.map((d, index) => (
                        <div
                          key={"salesList" + index}
                          className="flex w-full flex-col items-start justify-center gap-[5px] rounded-[12px] bg-white-A700 p-4 shadow-xs"
                        >
                          <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                            <Heading as="h1">{d.salesLabel}</Heading>
                            <Text size="xs" as="p" className="self-end !text-blue_gray-400">
                              6 May - 7 May
                            </Text>
                          </div>
                          <Heading size="xl" as="h2" className="!text-blue_gray-800">
                            {d.salesAmount}
                          </Heading>
                          <Heading as="h3" className="!text-blue_gray-800">
                            <span className="text-green-500">+55%</span>
                            <span className="text-blue_gray-800">&nbsp;</span>
                            <span className="font-normal text-blue_gray-400">since last month</span>
                          </Heading>
                        </div>
                      ))}
                    </Suspense>
                  </div>

                  <div className="flex gap-[23px] md:flex-col">
                    <div className="flex w-[33%] justify-center rounded-[12px] bg-white-A700 p-4 shadow-xs md:w-full">
                      <div className="flex w-full flex-col gap-[45px]">
                        <div className="flex flex-wrap items-center justify-between gap-5">
                          <Heading size="lg" as="h6" className="self-start !text-blue_gray-800">
                            Channels
                          </Heading>
                          <Heading
                            as="p"
                            className="flex h-[24px] w-[24px] items-center justify-center rounded-[12px] border border-solid border-blue_gray-400_01 text-center"
                          >
                            !
                          </Heading>
                        </div>
                        <div className="ml-[7px] flex w-[80%] md:ml-0 md:w-full">
                          <div className="flex w-full items-center gap-[30px]">
                            <Img src="images/img_chart.svg" alt="chart image" className="h-[198px] w-[198px]" />
                            <div className="flex w-[26%] flex-col gap-[18px]">
                              <div className="flex items-center gap-[9px]">
                                <div className="h-[8px] w-[8px] self-end rounded bg-blue-700" />
                                <Text size="xs" as="p" className="!text-blue_gray-800">
                                  Facebook
                                </Text>
                              </div>
                              <div className="flex w-[70%] items-center gap-[9px] md:w-full">
                                <div className="h-[8px] w-[8px] rounded bg-pink-500" />
                                <Text size="xs" as="p" className="!text-blue_gray-800">
                                  Direct
                                </Text>
                              </div>
                              <div className="flex items-start gap-[9px]">
                                <div className="h-[8px] w-[8px] rounded bg-blue_gray-800" />
                                <Text size="xs" as="p" className="!text-blue_gray-800">
                                  Organic
                                </Text>
                              </div>
                              <div className="flex items-center gap-[9px]">
                                <div className="h-[8px] w-[8px] self-end rounded bg-blue_gray-400_01" />
                                <Text size="xs" as="p">
                                  Referral
                                </Text>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between gap-5">
                          <Text as="p" className="w-[61%] leading-[140%]">
                            <span className="text-blue_gray-400_01">More than&nbsp;</span>
                            <span className="font-bold text-blue_gray-400_01">1,200,000</span>
                            <span className="text-blue_gray-400_01">
                              &nbsp;sales are made using referral marketing, and&nbsp;
                            </span>
                            <span className="font-bold text-blue_gray-400_01">700,000</span>
                            <span className="text-blue_gray-400_01">&nbsp;are from social media.</span>
                          </Text>
                          <Button shape="round" className="min-w-[115px] self-end sm:px-5">
                            READ MORE
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="flex-1 rounded-[12px] bg-white-A700 p-4 shadow-xs md:self-stretch">
                      <div className="mb-[15px] flex flex-col gap-2.5">
                        <div className="flex flex-wrap items-center justify-between gap-5">
                          <Heading size="lg" as="h6" className="!text-blue_gray-800">
                            Revenue
                          </Heading>
                          <Heading
                            as="p"
                            className="flex h-[24px] w-[24px] items-center justify-center rounded-[12px] border border-solid border-blue_gray-400_01 text-center"
                          >
                            !
                          </Heading>
                        </div>
                        <div>
                          <div className="flex flex-col gap-[39px]">
                            <div className="flex w-[29%] gap-px md:w-full md:flex-row">
                              <Suspense fallback={<div>Loading feed...</div>}>
                                {[...Array(2)].map((d, index) => (
                                  <LandingPageStackvectorOne key={"listVector" + index} />
                                ))}
                              </Suspense>
                            </div>
                            <div className="ml-[9px] md:ml-0">
                              <div className="flex flex-col gap-3.5">
                                <div className="flex items-center md:flex-col">
                                  <div className="flex w-[5%] flex-col items-start md:w-full">
                                    <Text as="p" className="!text-blue_gray-100">
                                      700
                                    </Text>
                                    <Text as="p" className="mt-3.5 !text-blue_gray-100">
                                      600
                                    </Text>
                                    <Text as="p" className="mt-3.5 !text-blue_gray-100">
                                      500
                                    </Text>
                                    <Text as="p" className="mt-3.5 !text-blue_gray-100">
                                      400
                                    </Text>
                                    <Text as="p" className="mt-3.5 !text-blue_gray-100">
                                      300
                                    </Text>
                                    <Text as="p" className="mt-3.5 !text-blue_gray-100">
                                      200
                                    </Text>
                                    <Text as="p" className="mt-3.5 !text-blue_gray-100">
                                      100
                                    </Text>
                                    <div className="h-[10px] w-[10px] self-end rounded-[5px] bg-pink-500" />
                                    <div className="flex w-[59%] items-start gap-[5px] self-end md:w-full">
                                      <Text as="p" className="!text-blue_gray-100">
                                        0
                                      </Text>
                                      <div className="h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                    </div>
                                  </div>
                                  <div className="relative ml-[-5px] flex flex-1 flex-col items-end md:ml-0 md:self-stretch">
                                    <div className="relative z-[6] h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                    <div className="relative mt-[-5px] flex items-center self-stretch md:flex-col">
                                      <div className="relative h-[216px] flex-1 md:h-auto md:w-full md:flex-none md:self-stretch">
                                        <div className="absolute bottom-[1.50px] left-[12%] m-auto flex w-[14%] justify-between gap-5">
                                          <div className="h-[10px] w-[10px] self-end rounded-[5px] bg-blue_gray-800" />
                                          <div className="h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                        </div>
                                        <div className="mb-[22px] flex w-[76%] flex-col items-end">
                                          <div className="relative z-[2] flex w-[51%] items-start justify-between gap-5 md:w-full">
                                            <div className="mt-[31px] h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                            <div className="mb-[22px] h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                            <div className="flex w-[3%] flex-col gap-[58px] sm:gap-[29px]">
                                              <div className="h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                              <div className="h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                            </div>
                                            <div className="mt-[15px] h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                          </div>
                                          <div className="flex items-start justify-between gap-5 self-stretch sm:flex-col">
                                            <div className="mt-[9px] flex w-[67%] flex-col items-start gap-0.5 sm:w-full">
                                              <div className="flex w-[76%] items-center justify-between gap-5 self-end md:w-full">
                                                <div className="h-[10px] w-[10px] self-end rounded-[5px] bg-pink-500" />
                                                <div className="flex w-[68%] items-start justify-between gap-5">
                                                  <div className="mt-[9px] h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                                  <div className="h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                                </div>
                                              </div>
                                              <div className="flex w-[76%] items-start justify-between gap-5 md:w-full">
                                                <div className="h-[10px] w-[10px] rounded-[5px] bg-pink-500" />
                                                <div className="mb-1.5 flex w-[36%] items-start justify-between gap-5">
                                                  <div className="mt-[9px] h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                                  <div className="h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="h-[10px] w-[10px] rounded-[5px] bg-blue_gray-800" />
                                          </div>
                                        </div>
                                        <div className="absolute bottom-0 left-0 right-0 top-0 z-[3] m-auto h-max w-full">
                                          <Img
                                            src="images/img_vector_pink_500.svg"
                                            alt="vector pink"
                                            className="relative z-[4] h-[203px] w-full md:h-auto"
                                          />
                                          <Img
                                            src="images/img_vector_blue_gray_800.svg"
                                            alt="vector blue"
                                            className="relative mt-[-72px] h-[84px] w-full md:h-auto"
                                          />
                                        </div>
                                      </div>
                                      <div className="relative z-[5] mb-[79px] ml-[-5px] h-[10px] w-[10px] self-end rounded-[5px] bg-blue_gray-800 md:ml-0" />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-wrap justify-between gap-5">
                                  <Text as="p" className="!text-blue_gray-100">
                                    Apr
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    May
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Jun
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Jul
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Aug
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Sep
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Oct
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Nov
                                  </Text>
                                  <Text as="p" className="!text-blue_gray-100">
                                    Dec
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex items-center gap-6 md:flex-col">
                <div className="flex flex-1 flex-col items-start gap-[42px] rounded-[12px] bg-white-A700 p-4 shadow-xs md:self-stretch">
                  <Heading size="lg" as="h6" className="mt-[5px] !text-blue_gray-800">
                    Sales by age
                  </Heading>
                  <div className="mb-[19px] ml-[9px] self-stretch md:ml-0">
                    <div className="flex items-start md:flex-col">
                      <div className="mt-2 flex flex-col items-end gap-5">
                        <Text as="p" className="!text-blue_gray-100">
                          16-20
                        </Text>
                        <Text as="p" className="!text-blue_gray-100">
                          21-25
                        </Text>
                        <Text as="p" className="!text-blue_gray-100">
                          26-30
                        </Text>
                        <Text as="p" className="!text-blue_gray-100">
                          31-36
                        </Text>
                        <Text as="p" className="!text-blue_gray-100">
                          36-42
                        </Text>
                        <Text as="p" className="!text-blue_gray-100">
                          42-50
                        </Text>
                        <Text as="p" className="!text-blue_gray-100">
                          50+
                        </Text>
                      </div>
                      <Text as="p" className="ml-[5px] !text-blue_gray-100 md:ml-0">
                        0
                      </Text>
                      <div className="relative ml-[-4px] flex flex-1 flex-col md:ml-0 md:self-stretch">
                        <div className="relative z-[7] flex items-center md:flex-col">
                          <div className="flex flex-1 flex-col items-center md:self-stretch">
                            <div className="flex flex-col items-start gap-1 self-stretch">
                              <div className="h-px w-full self-stretch bg-gray-200" />
                              <Img
                                src="images/img_vector_blue_gray_800_27x184.svg"
                                alt="image vector"
                                className="h-[27px] w-[25%]"
                              />
                              <div className="h-px w-full self-stretch bg-gray-200" />
                            </div>
                            <Img
                              src="images/img_vector_blue_gray_800_27x184.svg"
                              alt="image vector"
                              className="h-[27px] w-[33%] self-start"
                            />
                            <div className="mt-1 h-px w-full self-stretch bg-gray-200" />
                            <Img
                              src="images/img_vector_blue_gray_800_27x147.png"
                              alt="image vector"
                              className="h-[27px] w-[20%] self-start object-cover"
                            />
                            <div className="mt-[5px] flex flex-col gap-1 self-stretch">
                              <div className="h-px bg-gray-200" />
                              <div className="h-[27px] rounded-br rounded-tr bg-blue_gray-800" />
                              <div className="h-px bg-gray-200" />
                            </div>
                            <Img
                              src="images/img_vector_blue_gray_800_27x184.svg"
                              alt="image vector"
                              className="h-[27px] w-[33%] self-start"
                            />
                            <div className="mt-[5px] h-px w-full self-stretch bg-gray-200" />
                            <Img
                              src="images/img_vector_blue_gray_800_27x184.svg"
                              alt="image vector"
                              className="h-[27px] w-[25%] self-start"
                            />
                            <div className="mt-[5px] h-px w-full self-stretch bg-gray-200" />
                            <Img
                              src="images/img_vector_blue_gray_800_27x184.svg"
                              alt="image vector"
                              className="h-[27px] w-[42%] self-start"
                            />
                            <div className="mt-1 h-px w-full self-stretch bg-gray-200" />
                            <div className="mr-[114px] mt-5 flex w-[42%] flex-wrap justify-between gap-5 self-end md:mr-0 md:w-full">
                              <Text as="p" className="h-[17px] w-[16px] !text-blue_gray-100">
                                30
                              </Text>
                              <Text as="p" className="h-[17px] w-[16px] !text-blue_gray-100">
                                40
                              </Text>
                              <Text as="p" className="h-[17px] w-[16px] !text-blue_gray-100">
                                50
                              </Text>
                            </div>
                          </div>
                          <Text
                            as="p"
                            className="relative ml-[-7px] h-[17px] w-[16px] self-end !text-blue_gray-100 md:ml-0"
                          >
                            60
                          </Text>
                        </div>
                        <div className="relative ml-[114px] mt-[-17px] flex w-[22%] flex-wrap justify-between gap-5 md:ml-0 md:w-full">
                          <Text as="p" className="h-[17px] w-[16px] !text-blue_gray-100">
                            10
                          </Text>
                          <Text as="p" className="h-[17px] w-[16px] !text-blue_gray-100">
                            20
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-[33%] flex-col items-start justify-center gap-6 rounded-[12px] bg-white-A700 p-[11px] shadow-xs md:w-full">
                  <Heading size="lg" as="h6" className="ml-1.5 mt-[9px] !text-blue_gray-800 md:ml-0">
                    Sales by Country
                  </Heading>
                  <div className="mb-[11px] flex flex-col gap-[17px] self-stretch">
                    <div className="flex flex-1 pb-4 pr-4">
                      <div className="flex w-full items-start justify-between gap-5">
                        <div className="flex w-[38%] items-start justify-center gap-[13px]">
                          <Img
                            src="images/img_icon_40px_country_usa.png"
                            alt="country image"
                            className="h-[40px] w-[40px] object-cover"
                          />
                          <div className="flex flex-1 flex-col items-start">
                            <Heading size="s" as="p">
                              Country
                            </Heading>
                            <Text as="p" className="!text-blue_gray-800">
                              United States
                            </Text>
                          </div>
                        </div>
                        <div className="flex w-[44%] flex-col gap-0.5">
                          <div className="flex flex-wrap justify-between gap-5">
                            <Heading size="s" as="p">
                              Sales
                            </Heading>
                            <Heading size="s" as="p">
                              Bounce:
                            </Heading>
                          </div>
                          <div className="flex flex-wrap justify-between gap-5">
                            <Text as="p" className="!text-blue_gray-800">
                              2500
                            </Text>
                            <Text as="p" className="!text-blue_gray-800">
                              $29.9%
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-px w-[378px] bg-gray-50" />
                    <Img
                      src="images/img_rectangle.png"
                      alt="rectangle image"
                      className="h-[40px] object-cover md:w-full"
                    />
                    <div className="h-px w-[378px] bg-gray-50" />
                    <div className="mr-[25px] flex w-[85%] md:mr-0 md:w-full">
                      <div className="flex w-full justify-between gap-5">
                        <div className="flex flex-col items-start gap-0.5">
                          <Heading size="s" as="p">
                            Country
                          </Heading>
                          <Text as="p" className="!text-blue_gray-800">
                            Germany
                          </Text>
                        </div>
                        <div className="flex w-[52%] flex-col gap-0.5">
                          <div className="flex flex-wrap justify-between gap-5">
                            <Heading size="s" as="p">
                              Sales
                            </Heading>
                            <Heading size="s" as="p">
                              Bounce:
                            </Heading>
                          </div>
                          <div className="flex flex-wrap justify-between gap-5">
                            <Text as="p" className="!text-blue_gray-800">
                              3.900
                            </Text>
                            <Text as="p" className="!text-blue_gray-800">
                              40.22%
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-px w-[378px] bg-gray-50" />
                    <div className="flex flex-1 py-[15px] pr-[15px]">
                      <div className="flex w-full items-start justify-center gap-[13px]">
                        <Img
                          src="images/img_rectangle_40x40.png"
                          alt="image"
                          className="h-[40px] w-[40px] object-cover"
                        />
                        <div className="flex-1">
                          <div className="flex flex-wrap justify-between gap-5">
                            <Heading size="s" as="p">
                              Country
                            </Heading>
                            <Heading size="s" as="p">
                              Sales
                            </Heading>
                            <Heading size="s" as="p">
                              Bounce:
                            </Heading>
                          </div>
                          <div className="flex flex-wrap justify-between gap-5">
                            <Text as="p" className="!text-blue_gray-800">
                              Great Britain
                            </Text>
                            <Text as="p" className="!text-blue_gray-800">
                              1.400
                            </Text>
                            <Text as="p" className="!text-blue_gray-800">
                              23.44%
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="h-px w-[378px] bg-gray-50" />
                    <Img src="images/img_rectangle_1.png" alt="image" className="h-[40px] object-cover md:w-full" />
                    <div className="h-px w-[378px] bg-gray-50" />
                    <div className="mr-[25px] flex w-[85%] md:mr-0 md:w-full">
                      <div className="w-full">
                        <div className="flex flex-wrap justify-between gap-5">
                          <Heading size="s" as="p">
                            Country
                          </Heading>
                          <Heading size="s" as="p">
                            Sales
                          </Heading>
                          <Heading size="s" as="p">
                            Bounce:
                          </Heading>
                        </div>
                        <div className="flex flex-wrap justify-between gap-5">
                          <Text as="p" className="!text-blue_gray-800">
                            Brasil
                          </Text>
                          <Text as="p" className="!text-blue_gray-800">
                            562
                          </Text>
                          <Text as="p" className="!text-blue_gray-800">
                            32.14%
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="h-px w-[378px] bg-gray-50" />
                    <div className="flex flex-1 pr-[19px] pt-[19px]">
                      <div className="flex w-full items-start justify-between gap-5">
                        <div className="flex w-[65%] items-start justify-center gap-[13px]">
                          <Img
                            src="images/img_rectangle_2.png"
                            alt="image"
                            className="h-[40px] w-[40px] object-cover"
                          />
                          <div className="flex-1">
                            <div className="flex flex-wrap justify-between gap-5">
                              <Heading size="s" as="p">
                                Country
                              </Heading>
                              <Heading size="s" as="p">
                                Sales
                              </Heading>
                            </div>
                            <div className="flex flex-wrap justify-between gap-5">
                              <Text as="p" className="!text-blue_gray-800">
                                Australia
                              </Text>
                              <Text as="p" className="!text-blue_gray-800">
                                400
                              </Text>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-0.5">
                          <Heading size="s" as="p">
                            Bounce:
                          </Heading>
                          <Text as="p" className="!text-blue_gray-800">
                            56.83%
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-[82%] flex-col items-start gap-6 rounded-[12px] bg-white-A700 py-3.5 shadow-xs md:w-full">
            <Heading size="lg" as="h2" className="ml-[23px] mt-4 !text-blue_gray-800 md:ml-0">
              Top Selling Products
            </Heading>
            <ReactTable
              size="xs"
              bodyProps={{ className: "" }}
              headerProps={{ className: "flex-wrap" }}
              rowDataProps={{ className: "md:flex-col" }}
              className="self-stretch"
              columns={tableColumns}
              data={tableData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
