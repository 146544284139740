// src/components/RiskScoreMetrics.js
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import riskData from "./SocratIQ-demo-02b--RiskScore--Metrics.json";
import "./risk-score-metrics.css";

const RiskScoreMetrics = () => {
    useEffect(() => {
        // Extract supplier names, delivery delay days, and rejected percentage from JSON data
        const supplierNames = riskData.map((item) => item.supplier_name);
        const avgDeliveryDelayDays = riskData.map(
            (item) => item.avg_delivery_delay_days
        );
        const avgRejectedPct = riskData.map((item) =>
            parseFloat(item.avg_rejected_pct.replace("%", ""))
        );

        // Chart configuration
        const options = {
            chart: {
                height: "80%",
                type: "line", // Base chart type for line and bar combination
            },
            series: [
                {
                    name: "Avg Delivery Delay (Days)",
                    type: "column",
                    data: avgDeliveryDelayDays,
                },
                {
                    name: "Avg Rejected (%)",
                    type: "line",
                    data: avgRejectedPct,
                },
            ],
            xaxis: {
                categories: supplierNames,
                title: {
                    text: "Supplier Name",
                },
                labels: {
                    rotate: -45,
                },
            },
            yaxis: [
                {
                    title: {
                        text: "Avg Delivery Delay (Days)",
                    },
                },
                {
                    opposite: true,
                    title: {
                        text: "Avg Rejected (%)",
                    },
                    labels: {
                        formatter: (value) => `${value.toFixed(1)}%`,
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (value, { seriesIndex }) => 
                        seriesIndex === 1 ? `${value.toFixed(1)}%` : value.toFixed(1),
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val, { seriesIndex }) => 
                    seriesIndex === 1 ? `${val.toFixed(1)}%` : val.toFixed(1),
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"],
                },
            },
        };

        const chart = new ApexCharts(document.querySelector("#risk-chart"), options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <div className="risk-score-container">
            <h1>Risk Score Metrics Analysis</h1>
            <div id="risk-chart"></div>
        </div>
    );
};

export default RiskScoreMetrics;
