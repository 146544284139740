// CustomLogin.jsx
import React, { useEffect } from "react";
import {
  Authenticator,
  useAuthenticator,
  View,
  useTheme,
  Image,
  Text,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./CustomLogin.css";
import { useNavigate } from "react-router-dom";

const CustomLogin = () => {
  const navigate = useNavigate();
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const components = {
    // Header() {
    //   const { tokens } = useTheme();

    //   return (
    //     <View textAlign="center" padding={tokens.space.small}>
    //       <Image
    //         alt="Amplify logo"
    //         src="https://docs.amplify.aws/assets/logo-dark.svg"
    //       />
    //     </View>
    //   );
    // },

    Footer() {
      const { tokens } = useTheme();
      const date = new Date();
      const currentYear = date.getFullYear();

      return (
        <View textAlign="center" padding={tokens.space.small}>
          <Text color={tokens.colors.neutral[20]}>
            &copy; {currentYear} All Rights Reserved SocratIQ
          </Text>
        </View>
      );
    },
  };

  useEffect(() => {
    if (route === "authenticated") {
      console.log("User is authenticated, redirecting to landing page");
      navigate("/discover");
    }
  }, [route, navigate]);

  return (
    <div className="custom-login-wrapper">
      <div id="login-page-title">
        <h1>Welcome to SocratIQ</h1>
      </div>
      <div id="login-img-container">
        <img
          id="login-image"
          src="/images/bild-14.png"
          alt="login page graphic 14"
        />
      </div>
      {/* <div id="login-logo">
        <img src="/images/" />
      </div> */}
      <div className="custom-shape-divider-bottom-1710522688">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div id="login-form">
        <Authenticator components={components}>
          {({ signOut, user }) => {
            console.log("Authenticator user object:", user);
            if (user) {
              return (
                <div>
                  <p>Welcome {user.username}</p>
                  <button onClick={signOut}>Sign out</button>
                </div>
              );
            } else {
              return <p>Loading...</p>;
            }
          }}
        </Authenticator>
      </div>
    </div>
  );
};

export default CustomLogin;
