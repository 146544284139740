// File: src/pages/Compose/DocumentList.jsx
import React, { useState, useEffect } from "react";
import "./Compose.css";
import documentData from "../../document-list-01.json";
import Header from "../../components/Header"; // Import the Header component
import Sidebar2 from "../../components/Sidebar2"; // Import the Sidebar2 component

const DocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const [filters, setFilters] = useState({
    documentType: "All",
    status: "All",
    assignedDepartment: "All",
    projectNumber: "All",
    productType: "All",
    productNumber: "All",
  });

  const [filterOptions, setFilterOptions] = useState({
    documentTypes: [],
    statuses: [],
    assignedDepartments: [],
    projectNumbers: [],
    productTypes: [],
    productNumbers: [],
  });

  const [pdfSrc, setPdfSrc] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar2 state
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false); // Filter Sidebar state

  useEffect(() => {
    setDocuments(documentData);

    const documentTypes = [
      ...new Set(documentData.map((doc) => doc.document_type)),
    ];
    const statuses = [...new Set(documentData.map((doc) => doc.status))];
    const assignedDepartments = [
      ...new Set(documentData.map((doc) => doc.assigned_department)),
    ];
    const projectNumbers = [
      ...new Set(documentData.map((doc) => doc.project_number)),
    ];
    const productTypes = [
      ...new Set(documentData.map((doc) => doc.product_type)),
    ];
    const productNumbers = [
      ...new Set(documentData.map((doc) => doc.product_number)),
    ];

    setFilterOptions({
      documentTypes,
      statuses,
      assignedDepartments,
      projectNumbers,
      productTypes,
      productNumbers,
    });
  }, []);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.id.split("filter-")[1]]: e.target.value,
    });
  };

  const applyFilters = () => {
    const filteredDocuments = documentData.filter((doc) => {
      return (
        (filters.documentType === "All" ||
          doc.document_type === filters.documentType) &&
        (filters.status === "All" || doc.status === filters.status) &&
        (filters.assignedDepartment === "All" ||
          doc.assigned_department === filters.assignedDepartment) &&
        (filters.projectNumber === "All" ||
          doc.project_number === filters.projectNumber) &&
        (filters.productType === "All" ||
          doc.product_type === filters.productType) &&
        (filters.productNumber === "All" ||
          doc.product_number === filters.productNumber)
      );
    });
    setDocuments(filteredDocuments);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const filterSidebarToggle = () => {
    setFilterSidebarOpen(!filterSidebarOpen); // Correctly toggles the filter sidebar state
  };

  const handleRowClick = (pdfUrl) => {
    // Use the secure S3 URL to fetch the PDF
    const s3BaseUrl =
      "https://aiarchitectdemo-storage-5de59da6be472-master.s3.us-east-1.amazonaws.com"; // Your S3 HTTPS URL
    setPdfSrc(`${s3BaseUrl}${pdfUrl}`); // Ensure pdfUrl matches the correct case and path format, e.g., "/pdf-output/Doc-1001.pdf"
  };

  return (
    <div className="document-list-container">
      {/* Header Component */}
      <Header toggleSidebar={toggleSidebar} />

      {/* Sidebar Component */}
      <Sidebar2 isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Button to open the filter sidebar */}
      <button
        id="open-filter-sidebar-btn"
        className="open-filter-sidebar-btn"
        onClick={filterSidebarToggle} // Ensure this function toggles the sidebar
      >
        Table Filters
      </button>

      {/* Slide-out Filter Sidebar */}
      <div
        id="filter-sidebar"
        className={`filter-sidebar ${filterSidebarOpen ? "open" : ""}`} // Sidebar opens based on state
      >
        <button
          className="closebtn"
          id="filter-close-sidebar-btn"
          onClick={() => setFilterSidebarOpen(false)}
        >
          &times;
        </button>
        <h2>Filters</h2>
        <div className="filter-content">
          {/* Document Type Filter */}
          <div className="filter-group">
            <label htmlFor="filter-documentType">Document Type</label>
            <select id="filter-documentType" onChange={handleFilterChange}>
              <option value="All">All</option>
              {[...new Set(documentData.map((doc) => doc.document_type))].map(
                (type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                )
              )}
            </select>
          </div>

          {/* Status Filter */}
          <div className="filter-group">
            <label htmlFor="filter-status">Status</label>
            <select id="filter-status" onChange={handleFilterChange}>
              <option value="All">All</option>
              {[...new Set(documentData.map((doc) => doc.status))].map(
                (status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                )
              )}
            </select>
          </div>

          {/* Assigned Department Filter */}
          <div className="filter-group">
            <label htmlFor="filter-assignedDepartment">
              Assigned Department
            </label>
            <select
              id="filter-assignedDepartment"
              onChange={handleFilterChange}
            >
              <option value="All">All</option>
              {[
                ...new Set(documentData.map((doc) => doc.assigned_department)),
              ].map((department) => (
                <option key={department} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>

          {/* Project Number Filter */}
          <div className="filter-group">
            <label htmlFor="filter-projectNumber">Project Number</label>
            <select id="filter-projectNumber" onChange={handleFilterChange}>
              <option value="All">All</option>
              {[...new Set(documentData.map((doc) => doc.project_number))].map(
                (project) => (
                  <option key={project} value={project}>
                    {project}
                  </option>
                )
              )}
            </select>
          </div>

          {/* Product Type Filter */}
          <div className="filter-group">
            <label htmlFor="filter-productType">Product Type</label>
            <select id="filter-productType" onChange={handleFilterChange}>
              <option value="All">All</option>
              {[...new Set(documentData.map((doc) => doc.product_type))].map(
                (type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                )
              )}
            </select>
          </div>

          {/* Product Number Filter */}
          <div className="filter-group">
            <label htmlFor="filter-productNumber">Product Number</label>
            <select id="filter-productNumber" onChange={handleFilterChange}>
              <option value="All">All</option>
              {[...new Set(documentData.map((doc) => doc.product_number))].map(
                (number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                )
              )}
            </select>
          </div>

          <button
            id="apply-filters-btn"
            className="apply-filters-btn"
            onClick={applyFilters}
          >
            Apply Filters
          </button>
        </div>
      </div>

      {/* Table Container */}
      <div className="table-container">
        <table id="document-table">
          <thead>
            <tr>
              <th>Document Number</th>
              <th>Document Type</th>
              <th>Status</th>
              <th>Initiated Date</th>
              <th>Due Date</th>
              <th>Assigned Department</th>
              <th>Project Number</th>
              <th>Product Type</th>
              <th>Product Number</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => (
              <tr
                key={doc.document_number}
                onClick={() => handleRowClick(doc.url_path_pdf)}
              >
                <td>{doc.document_number}</td>
                <td>{doc.document_type}</td>
                <td>{doc.status}</td>
                <td>{doc.initiated_date}</td>
                <td>{doc.due_date}</td>
                <td>{doc.assigned_department}</td>
                <td>{doc.project_number}</td>
                <td>{doc.product_type}</td>
                <td>{doc.product_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* PDF Viewer Element */}
      <div className="pdf-viewer">
        <iframe
          id="pdf-viewer-frame"
          src={pdfSrc} // Set to S3 path
          width="90%"
          height="600px"
        ></iframe>
      </div>
    </div>
  );
};

export default DocumentList;
