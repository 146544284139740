import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import "../analyze.css"; // Shared CSS
import jsonData from "./exec-scpi.json"; // Import JSON directly

const ExecScpi = ({ chartId }) => {
  const [overallScore, setOverallScore] = useState(0);

  useEffect(() => {
    // Process data
    const labels = jsonData.map((item) => item.area_name);
    const values = jsonData.map((item) =>
      parseFloat(item.area_score_weighted)
    );
    const score = values.reduce((acc, val) => acc + val, 0).toFixed(2);

    setOverallScore(score);

    // Render chart
    const options = {
      chart: {
        type: "donut",
        height: "100%",
      },
      series: values,
      labels: labels,
      fill: {
        colors: [
          "#ee9b00",
          "#005f73",
          "#94d2bd",
          "#ca6702",
          "#e9d8a6",
          "#bb3e03",
          "#ae2012",
          "#9b2226",
          "#001219",
          "#0a9396",
        ],
      },
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: "16px",
          fontWeight: "normal",
          colors: ["#000000"],
        },
        formatter: function (val, { seriesIndex }) {
          return `${labels[seriesIndex]}: ${values[seriesIndex]}`;
        },
      },
      tooltip: {
        theme: false,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    const chart = new ApexCharts(document.querySelector(`#${chartId}`), options);
    chart.render();

    return () => {
      chart.destroy(); // Cleanup to avoid memory leaks
    };
  }, [chartId]);

  return (
    <div className="exec-container">
      <h1>Supply Chain Performance Index</h1>
      <div id={chartId}></div>
      <div id="overall-score">Overall Score: {overallScore}</div>
    </div>
  );
};

export default ExecScpi;
