// src/components/RiskScoreComposite.js
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import compositeData from "./SocratIQ-demo-02a--RiskScore--Composite.json";
import "./risk-score-composite.css";

const RiskScoreComposite = () => {
    useEffect(() => {
        // Extract supplier names and composite scores
        const supplierNames = compositeData.map((item) => item.supplier_name);
        const compositeScores = compositeData.map((item) => item.composite_score);

        // Chart configuration
        const options = {
            chart: {
                type: "bar",
                height: "80%",
            },
            series: [
                {
                    name: "Composite Score",
                    data: compositeScores,
                },
            ],
            xaxis: {
                categories: supplierNames,
                title: {
                    text: "Supplier Name",
                },
                labels: {
                    rotate: -45,
                },
            },
            yaxis: {
                title: {
                    text: "Composite Score",
                },
                labels: {
                    formatter: (value) => value.toFixed(2),
                },
            },
            tooltip: {
                y: {
                    formatter: (val) => val.toFixed(2),
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: "top",
                    },
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => val.toFixed(2),
                offsetY: -20,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"],
                },
            },
        };

        const chart = new ApexCharts(document.querySelector("#composite-chart"), options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <div className="composite-score-container">
            <h1>Risk Score Composite Analysis</h1>
            <div id="composite-chart"></div>
        </div>
    );
};

export default RiskScoreComposite;
