// File: src/components/LotAcceptanceApiData.js

import React, { useState, useEffect } from 'react';
import lotAcceptanceFile from '../../../LotAcceptance4Demo_api.json';

const LotAcceptanceApiData = () => {
  const [lotAcceptanceResult, setLotAcceptanceResult] = useState(null);
  const [error, setError] = useState(null);

  const fetchLotAcceptanceApiData = async () => {
    const url = 'https://app.studio.arria.com:443/alite_content_generation_webapp/text/QvxYABm1vYn';
    const bearerToken = 'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJmRXJYTlJhX2czR3lRVVBORVdUekJ6ZmEiLCJpYXQiOjE3MjQ4Nzc4NTgsImV4cCI6MTg4MjU1Nzg1OCwiaXNzIjoiQUxpdGUiLCJzdWIiOiJFZ2VOUnBWVGZTYzUiLCJBTGl0ZS5wZXJtIjpbInByczp4OlF2eFlBQm0xdlluIl0sIkFMaXRlLnR0IjoidV9hIn0.g_cuIof3cnDDUOeAv-PbPgfRwlPG4Gv59UWfdU4KKFs_umE43J7EWQVPj3OTFAuYCd3SljX6awm4uguEF4oChg';

    try {
      const binaryData = JSON.stringify(lotAcceptanceFile);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${bearerToken}`,
        },
        body: binaryData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      // Extract and clean the result
      if (Array.isArray(data) && data.length > 0 && data[0].hasOwnProperty('result')) {
        const rawResult = data[0].result;
        const cleanResult = rawResult.replace(/<[^>]+>/g, ''); // Remove HTML tags
        setLotAcceptanceResult(cleanResult);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching Lot Acceptance Chart API data:', error.message);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchLotAcceptanceApiData();
  }, []);

  return (
    <div className="api-container" style={{ padding: '10px', overflow: 'auto', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
      {error ? (
        <p style={{ color: 'red' }}>Error: {error}</p>
      ) : lotAcceptanceResult ? (
        <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          {lotAcceptanceResult}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default LotAcceptanceApiData;
