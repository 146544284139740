// AnomalyRange.js
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import jsonData from "./SocratIQ-demo-05a--Anomaly--range.json"; // Directly import JSON data

const AnomalyRange = () => {
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: { type: "line", width: "100%", height: "100%" },
            xaxis: {
                categories: [],
                title: { text: "Date" },
                labels: { rotate: -45 }
            },
            yaxis: { title: { text: "Temperature (°F)" } },
            tooltip: {
                shared: true,
                y: { formatter: val => `${val.toFixed(2)} °F` }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.9,
                    opacityTo: 0.8,
                    stops: [0, 100]
                }
            }
        }
    });

    useEffect(() => {
        const dates = jsonData.map(item => item.Date);
        const temperature = jsonData.map(item => item.Temperature);
        const tempRangeMin = jsonData.map(item => item.temp_range_min);
        const tempRangeMax = jsonData.map(item => item.temp_range_max);

        setChartData({
            series: [
                { name: "Temperature", data: temperature },
                { name: "Max Temperature", data: tempRangeMax, type: "area" },
                { name: "Min Temperature", data: tempRangeMin, type: "area" }
            ],
            options: {
                ...chartData.options,
                xaxis: { ...chartData.options.xaxis, categories: dates }
            }
        });
    }, []);

    return (
        <div id="chart" style={{ width: "70%", height: "70%" }}>
            <Chart options={chartData.options} series={chartData.series} type="line" />
        </div>
    );
};

export default AnomalyRange;
