// File: src/components/InvalidatedOOSChart.js

import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts'; // Ensure ApexCharts is installed
import invalidatedOOSData from '../../../invalidated-oos-mock-01.json'; // Adjust the path according to your project structure

const InvalidatedOOSChart = () => {
  // Create a ref to attach to the div where the chart will render
  const chartRef = useRef(null);

  useEffect(() => {
    // Aggregate data to calculate average invalidated-oos-rate for each product per year
    const aggregatedData = invalidatedOOSData.reduce((acc, item) => {
      const key = `${item.product}-${item.year}`;
      if (!acc[key]) {
        acc[key] = { sum: 0, count: 0 };
      }
      acc[key].sum += item['invalidated-oos-rate'];
      acc[key].count += 1;
      return acc;
    }, {});

    // Transform the aggregated data into a format suitable for the chart
    const processedData = {};
    Object.keys(aggregatedData).forEach((key) => {
      const [product, year] = key.split('-');
      const avgRate = aggregatedData[key].sum / aggregatedData[key].count;

      if (!processedData[product]) {
        processedData[product] = [];
      }
      processedData[product].push({ x: parseInt(year, 10), y: avgRate });
    });

    // Sort each product's data by year to ensure a continuous line
    Object.keys(processedData).forEach((product) => {
      processedData[product].sort((a, b) => a.x - b.x);
    });

    // Create the series for the chart
    const series = Object.keys(processedData).map((product) => ({
      name: product,
      data: processedData[product],
    }));

    // Define the chart options with updated tooltip settings
    const options = {
      chart: {
        type: 'line',
        width: '100%', // Set to fill the width of the container
        height: '100%', // Set to fill the height of the container
        toolbar: {
          show: true, // Enable toolbar for more control
        },
        animations: {
          enabled: true, // Enable animations for a smooth transition
        },
      },
      series: series,
      xaxis: {
        type: 'numeric',
        title: {
          text: 'Year',
        },
        labels: {
          formatter: (value) => Math.floor(value), // Display the year as a whole number
        },
      },
      yaxis: {
        title: {
          text: 'Invalidated OOS Rate (%)',
        },
        labels: {
          formatter: (value) => value + '%',
        },
      },
      tooltip: {
        x: {
          formatter: (value) => 'Year: ' + Math.floor(value),
        },
        y: {
          formatter: (value) => value.toFixed(1) + '%',
        },
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: 600, // Adjust height for responsiveness
            },
          },
        },
      ],
    };

    // Initialize and render the chart using the ref
    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    // Cleanup function to destroy the chart when the component is unmounted
    return () => {
      chart.destroy();
    };
  }, []);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />; // Use the ref here with styling to ensure full size
};

export default InvalidatedOOSChart;
