// /src/App.js

import React, { useEffect } from "react";
import "./App.css";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
// import Signinillustration from './pages/Signinillustration';
import Discover from "./pages/Discover";
import Compose from "./pages/Compose";
import CAPADashboard from "./pages/Analyze";
import Analyze2 from "./pages/Analyze2";
import Analyze3 from "./pages/Analyze3";
import Analyze4 from "./pages/Analyze4";
import CustomLogin from "./components/CustomLogin";
import ProtectedRoute from "./components/ProtectedRoute";

Amplify.configure(awsExports);

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();

  // Save the current path in localStorage on location change with error handling
  useEffect(() => {
    try {
      localStorage.setItem("lastVisitedPath", location.pathname);
    } catch (error) {
      console.error("Failed to save the last visited path:", error);
    }
  }, [location]);

  // Redirect to the last visited path on component mount with error handling
  useEffect(() => {
    try {
      const lastVisitedPath = localStorage.getItem("lastVisitedPath");
      if (lastVisitedPath && lastVisitedPath !== location.pathname) {
        navigate(lastVisitedPath, { replace: true });
      } else if (!lastVisitedPath) {
        navigate("/"); // Fallback to the login page if no path is stored
      }
    } catch (error) {
      console.error(
        "Failed to retrieve or navigate to the last visited path:",
        error
      );
      navigate("/"); // Fallback to the login page in case of an error
    }
  }, [location, navigate]);

  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<CustomLogin />} />
        {/* <Route path="/signinillustration" element={<Signinillustration />} /> */}
        <Route
          path="/landingpage"
          element={
            <ProtectedRoute>
              <LandingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/discover"
          element={
            <ProtectedRoute>
              <Discover />
            </ProtectedRoute>
          }
        />
        <Route
          path="/compose"
          element={
            <ProtectedRoute>
              <Compose />
            </ProtectedRoute>
          }
        />
        <Route
          path="/analyze"
          element={
            <ProtectedRoute>
              <CAPADashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/analyze2"
          element={
            <ProtectedRoute>
              <Analyze2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/analyze3"
          element={
            <ProtectedRoute>
              <Analyze3 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/analyze4"
          element={
            <ProtectedRoute>
              <Analyze4 />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <AppContent />
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
