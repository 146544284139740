// File: src/pages/AcumenRnDPage/index.jsx

import { Helmet } from "react-helmet";
import Sidebar2 from "../../components/Sidebar2";
import Header from "../../components/Header"; // Importing Header component
import React, { useState } from "react";

export default function AcumenClinicalPage() {
  const [isSidebar2Open, setSidebar2Open] = useState(false); // State for managing Sidebar2 visibility

  // Function to toggle Sidebar2 visibility
  const toggleSidebar2 = () => {
    setSidebar2Open(!isSidebar2Open);
  };

  return (
    <>
      <Helmet>
        <title>Acumen R&D</title>
        <meta
          name="description"
          content="Discover the powerful features of AI Architect Compose. From composing to analyzing, learn how our platform can streamline your workflow."
        />
      </Helmet>

      {/* Header Component */}
      <Header toggleSidebar={toggleSidebar2} />

      <div className="flex w-full h-screen">
        {/* Sidebar2 Component */}
        <Sidebar2
          isOpen={isSidebar2Open}
          toggleSidebar={toggleSidebar2}
          className="w-1/4 h-full"
        />

        {/* Main Content with iframe */}
        <div className="flex-grow p-4">
          <iframe
            src="https://workspace-preview.neo4j.io/workspace/explore"
            title="Neo4j Workspace"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          ></iframe>
        </div>
      </div>
    </>
  );
}
