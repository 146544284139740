import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Card,
  CardContent,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import ApexCharts from 'react-apexcharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// KPI Data for Header Section
const kpiData = [
  {
    title: "Supply Chain Index",
    value: 85,
    trend: "Improving",
    trendIcon: <ArrowUpwardIcon style={{ color: 'green' }} />,
    status: "Good",
    color: "green",
  },
  {
    title: "Risk & Compliance",
    value: "High Risk",
    trend: "Worsening",
    trendIcon: <ArrowDownwardIcon style={{ color: 'red' }} />,
    status: "High Risk",
    color: "red",
  },
  {
    title: "Patient Impact",
    value: "78% Retention",
    trend: "Stable",
    trendIcon: <ArrowForwardIcon style={{ color: 'orange' }} />,
    status: "Stable",
    color: "yellow",
  },
  {
    title: "Sustainability",
    value: "50% Recycling",
    trend: "Improving",
    trendIcon: <ArrowUpwardIcon style={{ color: 'green' }} />,
    status: "Improving",
    color: "green",
  },
];

// Header Section Component
const HeaderSection = () => (
  <Box sx={{ p: 2 }}>
    <Typography variant="h5" gutterBottom>
      KPI Overview
    </Typography>
    <Grid container spacing={3}>
      {kpiData.map((kpi, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              p: 2,
              textAlign: 'center',
              borderLeft: `5px solid ${kpi.color}`,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {kpi.title}
            </Typography>
            <Typography variant="h4" sx={{ color: kpi.color, fontWeight: 'bold' }}>
              {kpi.value}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
              {kpi.trendIcon}
              <Typography variant="body2" sx={{ ml: 1 }}>
                {kpi.trend}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const Dashboard = () => {
  // Data for ApexCharts (replace with real data)
  const scpiData = {
    series: [40, 30, 20, 10],
    options: {
      chart: { type: 'donut' },
      labels: ['Drug Availability', 'Stock-Out Events', 'Lead Time', 'Inventory Turnover'],
      colors: ['#4caf50', '#ff9800', '#f44336', '#2196f3'],
    },
  };

  const heatmapData = {
    series: [
      { name: 'Phase I', data: [3, 4, 2, 5] },
      { name: 'Phase II', data: [2, 3, 5, 4] },
      { name: 'Phase III', data: [4, 5, 3, 2] },
    ],
    options: {
      chart: { type: 'heatmap' },
      dataLabels: { enabled: false },
      colors: ['#008FFB'],
      xaxis: { categories: ['Supplier Risk', 'Audit Status', 'Custody Compliance', 'Delays'] },
    },
  };

  return (
    <Box>
      {/* App Bar */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            SocratIQ Executive Dashboard
          </Typography>
          <Avatar alt="User" src="/static/images/avatar/1.jpg" />
        </Toolbar>
      </AppBar>

      {/* Header Section */}
      <HeaderSection />

      {/* Tabs */}
      <Box sx={{ p: 2 }}>
        <Tabs value={0}>
          {['SCPI', 'RCI', 'CCII', 'CEI', 'PII', 'SPI', 'SI'].map((indexName, idx) => (
            <Tab key={idx} label={indexName} />
          ))}
        </Tabs>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Detailed insights for the selected index will appear here.
        </Typography>
      </Box>

      {/* Visual Insights */}
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Supply Chain Performance Index (SCPI)
            </Typography>
            <ApexCharts options={scpiData.options} series={scpiData.series} type="donut" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Risk and Compliance Index (RCI)
            </Typography>
            <ApexCharts options={heatmapData.options} series={heatmapData.series} type="heatmap" />
          </Grid>
        </Grid>
      </Box>

      {/* Alerts and Recommendations */}
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Alerts and Recommendations
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Phase</TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[['Stock-Out', 'Phase II', 'Site 7', '2024-11-20 12:00', 'Review']].map((alert, index) => (
              <TableRow key={index}>
                {alert.map((cell, i) => (
                  <TableCell key={i}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Footer */}
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Button variant="contained" color="primary" sx={{ mr: 2 }}>
          Export Data
        </Button>
        <Button variant="outlined" color="primary">
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
