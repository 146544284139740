// Timeline.js

import React, { useEffect, useState } from 'react';
import './infographic.css';
import timelineData from './infographic-timeline.json';

const statusColors = {
  Complete: "status-complete",
  "In Progress": "status-in-progress",
  "Not Started": "status-not-started",
  "Past Due": "status-past-due",
};

// ProgressBar component
const ProgressBar = ({ markers }) => (
  <div className="progress-bar">
    <div id="progress-indicators">
      {markers.map((marker, index) => (
        <div
          key={index}
          className={`progress-indicator ${statusColors[marker.milestone_item_status] || "status-not-started"}`}
        />
      ))}
    </div>
  </div>
);

// TimelinePath component
const TimelinePath = () => (
  <svg className="timeline-path" viewBox="0 0 1000 500" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <marker id="arrowhead" markerWidth="4" markerHeight="4" refX="3" refY="2" orient="auto">
        <polygon points="0 0, 4 2, 0 4" fill="#b0b0b0" />
      </marker>
    </defs>
    <path
      d="M50 250 Q300 100 600 300 T940 200"
      stroke="#b0b0b0"
      strokeWidth="20"
      fill="none"
      markerEnd="url(#arrowhead)"
    />
    <text x="100" y="240" className="year">2024</text>
    <text x="750" y="180" className="year">2025</text>
  </svg>
);

// Milestone component
const Milestone = ({ marker, relatedItems, index }) => {
  const leftPosition = 10 + index * 20;
  const topPosition = index % 2 === 0 ? 20 : 60;

  return (
    <div className="milestone" style={{ left: `${leftPosition}%`, top: `${topPosition}%` }}>
      <div className={`icon ${statusColors[marker.milestone_item_status] || "status-not-started"}`}>
        <span>{new Date(marker.milestone_item_target_date).toLocaleDateString("en-US", { month: "short", day: "numeric" })}</span>
      </div>
      <div className="label">{marker.milestone_item_display_text}</div>
      <div className="description">{marker.milestone_item_status}</div>
      <ul className="item-list">
        {relatedItems.map((item, idx) => (
          <li key={idx}>{item.milestone_item_display_text}</li>
        ))}
      </ul>
    </div>
  );
};

// Main Timeline component
const Timeline = () => {
  const [markers, setMarkers] = useState([]);
  const [itemsByMarker, setItemsByMarker] = useState({});

  useEffect(() => {
    const markerData = timelineData.filter(item => item.milestone_item_type === "Marker");
    const items = timelineData.reduce((acc, item) => {
      if (item.milestone_item_type === "List Item") {
        (acc[item.milestone_marker_id] = acc[item.milestone_marker_id] || []).push(item);
      }
      return acc;
    }, {});
    setMarkers(markerData);
    setItemsByMarker(items);
  }, []);

  return (
    <div>
      <h1 className="title">Launch Timeline</h1>
      <ProgressBar markers={markers} />
      <div className="timeline-container">
        <TimelinePath />
        <div id="milestones">
          {markers.map((marker, index) => (
            <Milestone
              key={marker.milestone_record_id}
              marker={marker}
              relatedItems={itemsByMarker[marker.milestone_marker_id] || []}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
