import React, { useState } from "react";
import QualityChart from "./product_quality/product";
import LotAcceptanceChart from "./lot_acceptance/acceptance";
import InvalidatedOOSChart from "./invalidated_oos/invalidated";
import ComplaintRateChart from "./complaint_rate/complaint";
import QualityApiData from "./product_quality/QualityApiData";
import LotAcceptanceApiData from "./lot_acceptance/LotAcceptanceApiData";
import InvalidatedOOSApiData from "./invalidated_oos/InvalidatedOOSApiData";
import ComplaintRateApiData from "./complaint_rate/ComplaintRateApiData";
import InfographicTimeline from "./infographics/index";
import AnomalyHistorical from "./anomaly-range/index";
import RiskScoreMetrics from "./RiskScoreMetrics";
import RiskScoreComposite from "./RiskScoreComposite";
import Header from "../../components/Header";
import Sidebar2 from "../../components/Sidebar2";
import CentralizedResilience from "./exec-centralized/index";
import DecentralizedResilience from "./exec-decentralized/index";
import KeyMetricsAnalysis from "./exec--keyMetrics/index";
import KeyMetricsBarChart from "./exec-keyMetrics-bar/index";
import OverallScoreChart from "./exec-overall/index";
import ExecCcii from "./exec-ccii/index";
import ExecCei from "./exec-cei/index";
import ExecFai from "./exec-fai/index";
import ExecPii from "./exec-pii/index";
import ExecRci from "./exec-rci/index";
import ExecScpi from "./exec-scpi/index";
import ExecSi from "./exec-si/index";
import ExecSpi from "./exec-spi/index";
import Scpi from "./scpi";
import graph1 from "./graph1.png";
import graph2 from "./graph2.png";
import graph3 from "./graph3.png";
import graph4 from "./graph4.png";
import graph5 from "./graph5.png";
import graph6 from "./graph6.png";
import graph7 from "./graph7.png";
import graph9 from "./graph9.png";
import graph10 from "./graph10.png";
import "./analyze.css";

const CAPADashboardGrid = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [activeTopRow, setActiveTopRow] = useState("executive");
    const [favorites, setFavorites] = useState([]);
    const [transitionClass, setTransitionClass] = useState("fade-in");

    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

    const openModal = (chartComponent, apiDataComponent, fullScreen = false) => {
        if (React.isValidElement(chartComponent) && chartComponent.type === ExecScpi) {
            // Dynamically load Scpi component for ExecScpi
            setModalContent({ chartComponent: <Scpi />, apiDataComponent: null });
        } else {
            setModalContent({ chartComponent, apiDataComponent });
        }
        setIsFullScreen(fullScreen);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => {
            setModalContent(null);
            setIsFullScreen(false);
        }, 300);
    };

    const handleTopRowClick = (id) => {
        if (id === activeTopRow) return;

        setTransitionClass("fade-out");
        setTimeout(() => {
            setActiveTopRow(id);
            setTransitionClass("fade-in");
        }, 500);
    };

    const addFavorite = (item) => {
        if (!favorites.some((fav) => fav.title === item.title)) {
            setFavorites([...favorites, item]);
        }
    };

    const removeFavorite = (title) => {
        setFavorites(favorites.filter((item) => item.title !== title));
    };

    const topRowItems = [
        { id: "executive", title: "Executive" },
        { id: "operations", title: "Operations" },
        { id: "supplier-analysis", title: "Supplier Analysis" },
        { id: "favorites", title: "Favorites" },
    ];

    const executiveGridItems = [
        { chartComponent: <ExecCcii chartId="chart-ccii" /> },
        { chartComponent: <ExecCei chartId="chart-cei" /> },
        { chartComponent: <ExecFai chartId="chart-fai" /> },
        { chartComponent: <ExecPii chartId="chart-pii" /> },
        { chartComponent: <ExecRci chartId="chart-rci" /> },
        { chartComponent: <ExecScpi chartId="chart-scpi" /> },
        { chartComponent: <ExecSi chartId="chart-si" /> },
        { chartComponent: <ExecSpi chartId="chart-spi" /> },
        { chartComponent: <div>Placeholder 9</div> },
    ];

    const operationsGridItems = [
        { title: "Quality Chart", image: graph1, chartComponent: <QualityChart />, apiDataComponent: <QualityApiData /> },
        { title: "Lot Acceptance Chart", image: graph2, chartComponent: <LotAcceptanceChart />, apiDataComponent: <LotAcceptanceApiData /> },
        { title: "Invalidated OOS Chart", image: graph3, chartComponent: <InvalidatedOOSChart />, apiDataComponent: <InvalidatedOOSApiData /> },
        { title: "Complaint Rate Chart", image: graph4, chartComponent: <ComplaintRateChart />, apiDataComponent: <ComplaintRateApiData /> },
        {
            title: "Decentralized Clinical Trial Distribution",
            image: graph5,
            chartComponent: (
                <iframe
                    src="https://workspace-preview.neo4j.io/workspace/explore"
                    title="Neo4j Workspace"
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                />
            ),
            apiDataComponent: null,
            fullScreen: true,
        },
        { title: "Timeline", image: graph6, chartComponent: <InfographicTimeline /> },
        { title: "Anomaly Range Chart", image: graph7, chartComponent: <AnomalyHistorical /> },
        { title: "Risk Score Metrics", image: graph9, chartComponent: <RiskScoreMetrics /> },
        { title: "Risk Score Composite", image: graph10, chartComponent: <RiskScoreComposite /> },
    ];

    const supplierAnalysisGridItems = [
        { title: "Centralized Resilience", chartComponent: <CentralizedResilience /> },
        { title: "Decentralized Resilience", chartComponent: <DecentralizedResilience /> },
        { title: "Key Metrics Analysis", chartComponent: <KeyMetricsAnalysis /> },
        { title: "Key Metrics Bar Chart", chartComponent: <KeyMetricsBarChart /> },
        { title: "Overall Score Chart", chartComponent: <OverallScoreChart /> },
        { title: "Placeholder 6", chartComponent: <div>Placeholder 6</div> },
        { title: "Placeholder 7", chartComponent: <div>Placeholder 7</div> },
        { title: "Placeholder 8", chartComponent: <div>Placeholder 8</div> },
        { title: "Placeholder 9", chartComponent: <div>Placeholder 9</div> },
    ];

    const getGridItems = () => {
        switch (activeTopRow) {
            case "operations":
                return operationsGridItems;
            case "supplier-analysis":
                return supplierAnalysisGridItems;
            case "favorites":
                return favorites;
            default:
                return executiveGridItems;
        }
    };

    return (
        <div className="page-container">
            <Header refreshPage={() => window.location.reload()} toggleSidebar={toggleSidebar} />
            <Sidebar2 isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

            <div className="sub-content">
                <div className="grid-container-1x4">
                    {topRowItems.map((item) => (
                        <div key={item.id} className={`grid-item ${activeTopRow === item.id ? "active" : ""}`} onClick={() => handleTopRowClick(item.id)}>
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>

                <div className="wide-box">
                    <h3>Arria API Data</h3>
                </div>

                <div className={`grid-container-3x3 ${transitionClass}`}>
                    {getGridItems().map((item, index) => (
                        <div className="grid-item" key={index} onClick={() => openModal(item.chartComponent, item.apiDataComponent, item.fullScreen)}>
                            <h3>{item.title}</h3>
                            {activeTopRow === "executive" ? (
                                <div className="chart-preview">{item.chartComponent}</div>
                            ) : (
                                item.image && <img src={item.image} alt={`${item.title} Preview`} />
                            )}
                            {activeTopRow !== "favorites" ? (
                                <button onClick={(event) => { event.stopPropagation(); addFavorite(item); }}>Add to Favorites</button>
                            ) : (
                                <button onClick={(event) => { event.stopPropagation(); removeFavorite(item.title); }}>Remove from Favorites</button>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {modalContent && (
                <div className={`modal-overlay ${isModalOpen ? "open" : ""}`} onClick={closeModal}>
                    <div className={`modal-content ${isFullScreen ? "full-screen" : ""}`} onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={closeModal}>X</button>
                        <div className="modal-chart">{modalContent.chartComponent}</div>
                        {modalContent.apiDataComponent && <div className="modal-api-data">{modalContent.apiDataComponent}</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CAPADashboardGrid;
